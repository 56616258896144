<!--采购入库-->
<template>
    <div class="In">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                style="text-align: right"
                v-model="form"
                size="small"
                label-width="80px"
            >
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="单据号">
                            <el-input placeholder="" v-model="form.code" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="采购机构">
                            <el-select placeholder="请选择" v-model="form.deptCode" filterable>
                                <el-option value="" label="请选择" />
                                <el-option
                                    v-for="dept in depts"
                                    :label="dept.name"
                                    :value="dept.code"
                                    :key="dept.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="单据状态">
                            <el-select placeholder="请选择" v-model="form.stockStatus">
                                <el-option v-for="e in meta.inEnums" :label="e.label" :value="e.code" :key="e.code" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 16px">
                    <el-col :span="8">
                        <ef-start-date v-model="form.startTime" />
                    </el-col>
                    <el-col :span="8">
                        <ef-end-date v-model="form.endTime" />
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button type="primary" @click="handleQuery" size="small" v-if="hasPrivilege('menu.purchase.in.open')">
                查询
            </el-button>
            <el-button type="primary" @click="handleIn" size="small" v-if="hasPrivilege('menu.purchase.in.stock')"
                >入库
            </el-button>
            <el-button type="primary" @click="handleExport" size="small" v-if="hasPrivilege('menu.purchase.in.export')">
                导出
            </el-button>
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                border
                stripe
                style="width: 100%"
                ref="table"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="440"
            >
                <el-table-column label="全选" type="selection" width="60" align="center" fixed="left" />
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column label="入库单" width="200" prop="code" v-if="showColumn('code')" />
                <el-table-column
                    label="申请单"
                    width="200"
                    prop="purchaseApplyCode"
                    v-if="showColumn('purchaseApplyCode')"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            @click="toUrl(scope.row)"
                            size="small"
                            v-if="hasPrivilege('menu.purchase.order.open')"
                            >{{ scope.row.purchaseApplyCode }}
                        </el-button>
                    </template>
                </el-table-column>

                <el-table-column label="配送中心" width="160" prop="repoName" v-if="showColumn('repoName')" />
                <el-table-column label="采购机构" width="160" prop="deptName" v-if="showColumn('deptName')" />
                <el-table-column label="总数" width="60" prop="totalCount" v-if="showColumn('totalCount')" />
                <el-table-column
                    label="单据金额"
                    width="140"
                    prop="bizMoney"
                    key="bizMoney"
                    v-if="showColumn('bizMoney') && showInPrice()"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row | total }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="单据状态"
                    width="160"
                    prop="stockStatus"
                    key="stockStatus"
                    v-if="showColumn('stockStatus')"
                    sortable
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.stockStatus | stockInStatus }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="入库时间"
                    width="160"
                    prop="stockChangeTime"
                    v-if="showColumn('stockChangeTime')"
                />
                <el-table-column label="创建人" width="120" prop="creator" v-if="showColumn('creator')" />
                <el-table-column label="创建时间" width="160" prop="createTime" v-if="showColumn('createTime')" />
                <el-table-column
                    label="操作"
                    min-width="75"
                    header-align="center"
                    prop="operate"
                    v-if="showColumn('operate')"
                    key="operate"
                >
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            @click="rowDetail(scope.row)"
                            v-if="hasPrivilege('menu.purchase.in.open')"
                            >查看
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
    </div>
</template>

<script>
import Util from 'js/Util';
import UrlUtils from 'js/UrlUtils';
import MoneyUtils from 'js/MoneyUtils';
import Decimal from 'decimal';
import EfStartDate from 'components/EfStartDate';
import EfEndDate from 'components/EfEndDate';
import { DeptTypeEnum } from 'js/DeptCommon';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';

export default {
    name: 'PurchaseIn',
    components: { EfEndDate, EfStartDate, CheckTableShowColumnDialog },
    mixins: [CheckTableShowColumn],
    data() {
        return {
            form: {
                code: '',
                deptCode: '',
                stockStatus: '',
                startTime: Util.formatDateOfLastMonth(),
                endTime: Util.nowFormatDate(),
                page: 1,
                limit: Util.Limit,
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            loading: false,
            tableData: [],
            url: {
                page: '/purchase/in/page',
            },
            meta: {
                reviewEnums: Util.reviewStatusEnum(true),
                inEnums: Util.stockInStatusEnum(true),
            },
            depts: [],
            linkUrl: 'order/detail/',
        };
    },
    mounted() {
        const _this = this;
        this.$efApi.deptApi.managedDeptsByDeptType(DeptTypeEnum.STORE).then((rst) => {
            _this.depts = rst;
            _this.form.deptCode = _this.depts[0].code;
            _this.handleQuery();
        });
    },
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    methods: {
        handlePrint() {
            this.printHTML('printMe', this.$route.meta.title);
        },
        toUrl(item) {
            this.$router.push(this.linkUrl + item.purchaseApplyCode);
        },
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params);
        },
        rowDetail(row) {
            Util.nameJump(this, 'menu.purchase.in.detail', ['采购管理', '采购入库', '采购入库详情'], {
                form: row,
                code: row.code,
            });
        },
        handleExport() {
            const codes = (this.$refs.table.selection || []).map((e) => e.code);

            UrlUtils.Export(this, '采购入库', '/purchase/in/export', this.form, codes);
        },
        handleIn() {
            if (this.$refs['table'].selection.length != 1) {
                this.$message.error('选择单据数量必须为一条');
            } else if (this.$refs['table'].selection[0].stockStatus != 0) {
                this.$message.error('商品仅能入库一次');
            } else {
                this.$confirm('确认入库该单据么?入库后该单据商品库存将立即变更,该操作不可撤销,请小心操作!', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                })
                    .then(() => {
                        const params = {
                            codes: this.$refs['table'].selection[0].code,
                        };
                        UrlUtils.PostRemote(this, '/purchase/in/in', params, null, () => {
                            this.handleQuery();
                            this.$message.success('操作成功');
                        });
                    })
                    .catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消入库',
                        });
                    });
            }
        },
    },
    filters: {
        total(row) {
            const a = row.counts.reduce((accCount, curCount, curIdx) => {
                return new Decimal(curCount).mul(row.prices[curIdx]).add(accCount);
            }, new Decimal(0));
            return MoneyUtils.moneyToThree(a);
        },
    },
};
</script>

<style scoped>
.In .el-form-item {
    margin-bottom: 0;
}
</style>
